import { motion } from 'framer-motion';
import { StaticImage } from 'gatsby-plugin-image';

export default function Covid({ i }: { i: number }) {
  const getRandomInt = (min: number, max: number) =>
    Math.floor(
      Math.random() * (Math.floor(max) - Math.ceil(min)) + Math.ceil(min)
    );

  const delay = 0;

  return (
    <motion.div
      className={`covid covid--${i.toString()}`}
      initial={{ opacity: 0, rotate: 359, scale: 0.75 }}
      animate={{ opacity: 1, rotate: 0, scale: 1 }}
      transition={{
        opacity: { delay, duration: 0.5 },
        scale: { delay, duration: 0.5 },
      }}
    >
      <div
        style={{
          animation: `covid-rotate ${getRandomInt(40, 60)}s linear`,
          animationIterationCount: 'infinite',
        }}
      >
        <StaticImage
          src="../images/covid.png"
          alt=""
          layout="fullWidth"
          placeholder="none"
        />
      </div>
    </motion.div>
  );
}

import { PetitionSection } from '@halcyon/lib';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import WhyMatterSection from '../components/WhyMatterSection';
import HeroSection from '../components/HeroSection';
import SEO from '../components/Seo';

export default function HomePage() {
  const {
    site: {
      siteMetadata: { branch },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          branch
        }
      }
    }
  `);

  return (
    <>
      <Helmet
        htmlAttributes={{ 'data-theme': branch === 'dark' ? 'dark' : 'light' }}
      />
      <SEO
        title="COVID-19: We Deserve the Truth"
        description="Recently, the U.S. National Institutes of Health (NIH) revealed in a letter sent to House Republicans that NIH grant-funded experiments at the Wuhan Institute of Virology in China had the “unexpected result” of creating a coronavirus that was more infectious."
      />
      <main className="page-home">
        <HeroSection />
        <WhyMatterSection />
        <PetitionSection
          title="Do you agree that Dr. Fauci and the American government need to be more transparent about the nature of this research and its connection to the COVID-19 pandemic?"
          subtitle="If so, sign our petition and unite with others who demand to know the truth."
          branch={branch}
        />
      </main>
    </>
  );
}

import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import Covid from './Covid';

const draw = {
  hidden: { opacity: 0, y: 10 },
  visible: (i: number) => {
    const delay = 0.25;
    return {
      y: 0,
      opacity: 1,
      transition: {
        y: { delay, type: 'spring', duration: 0.5 },
        opacity: { delay, type: 'spring', duration: 0.5 },
      },
    };
  },
};

function NIHLogos() {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 950 623"
      fill="#616265"
      fillOpacity=".14"
      initial="hidden"
      animate="visible"
    >
      <motion.path
        variants={draw}
        custom={3}
        d="m254.943 232.241 28.387-51.157-28.278-51.049h7.069c3.916 0 8.592 2.788 10.441 6.113l24.798 44.936-24.907 44.937c-1.848 3.432-6.634 6.113-10.441 6.113h-7.069v.107Z"
      />
      <motion.path
        variants={draw}
        custom={2}
        d="M143.669 130.035c-5.22 0-9.461 4.183-9.461 9.438v83.33c0 5.148 4.241 9.439 9.461 9.439h104.195l28.386-51.158-28.386-51.049H143.669Zm6.418 28.313h8.484l20.881 33.676h.109v-33.676h7.613v45.687h-8.482l-20.773-33.569h-.219v33.569h-7.613v-45.687Zm45.027 0h8.049v45.687h-8.049v-45.687Zm15.987 0h8.049v18.125h20.884v-18.125h7.938v45.687h-8.049v-20.591H219.15v20.591h-8.049v-45.687ZM254.879 348.963l28.387-51.157-28.278-51.05h7.069c3.915 0 8.592 2.789 10.441 6.113l24.798 44.937-24.907 44.936c-1.849 3.432-6.634 6.113-10.441 6.113h-7.069v.108Z"
      />
      <motion.path
        variants={draw}
        custom={1}
        d="M143.605 246.756c-5.22 0-9.461 4.184-9.461 9.439v83.33c0 5.148 4.241 9.438 9.461 9.438H247.8l28.386-51.158-28.386-51.049H143.605Zm6.418 28.314h8.484l20.881 33.676h.109V275.07h7.613v45.687h-8.482l-20.773-33.569h-.219v33.569h-7.613V275.07Zm45.027 0h8.049v45.687h-8.049V275.07Zm15.987 0h8.049v18.125h20.884V275.07h7.938v45.687h-8.049v-20.591h-20.773v20.591h-8.049V275.07ZM254.879 465.684l28.387-51.156-28.278-51.05h7.069c3.915 0 8.592 2.788 10.441 6.113l24.798 44.937-24.907 44.936c-1.849 3.432-6.634 6.113-10.441 6.113h-7.069v.107Z"
      />
      <motion.path
        variants={draw}
        custom={0}
        d="M143.605 363.478c-5.22 0-9.461 4.183-9.461 9.439v83.33c0 5.147 4.241 9.438 9.461 9.438H247.8l28.386-51.158-28.386-51.049H143.605Zm6.418 28.314h8.484l20.881 33.676h.109v-33.676h7.613v45.686h-8.482l-20.773-33.569h-.219v33.569h-7.613v-45.686Zm45.027 0h8.049v45.686h-8.049v-45.686Zm15.987 0h8.049v18.125h20.884v-18.125h7.938v45.686h-8.049v-20.59h-20.773v20.59h-8.049v-45.686Z"
      />
    </motion.svg>
  );
}

function HeroDescription() {
  return (
    <>
      <p>
        “[T]his was an unexpected result of the research, as opposed to
        something that the researchers set out to do," Lawrence Tabak, Acting
        Director of NIH, said in the letter.
      </p>
      <p>
        These experiments were funded by the NIH while{' '}
        <a
          href="https://www.niaid.nih.gov/about/director"
          target="_blank"
          rel="noopener noreferrer"
        >
          Dr. Anthony Fauci
        </a>{' '}
        was serving as director of the National Institute of Allergy and
        Infectious Diseases (NIAID), a role he continues to occupy.
      </p>
      <p>
        These{' '}
        <a
          href="https://reporter.nih.gov/search/yi9zmxlHDUO8yZkhiDs2uA/project-details/8674931#similar-Projects"
          target="_blank"
          rel="noopener noreferrer"
        >
          experiments
        </a>{' '}
        also occurred shortly before the{' '}
        <a href="https://pubmed.ncbi.nlm.nih.gov/32982600/">outbreak</a> of the
        COVID-19 pandemic in Wuhan.
      </p>
    </>
  );
}

export default function HeroSection() {
  return (
    <section className="container hero">
      <div className="row bottom-xs">
        <div className="col-xs-12 col-sm-10 col-md-6 col-lg-5 col-lg-offset-1">
          <div className="hero__text">
            <h1 className="hero__title">COVID-19: We Deserve the Truth</h1>
            <p>
              <strong>
                Recently, the U.S. National Institutes of Health (NIH) revealed
                in a{' '}
                <a
                  href="https://republicans-energycommerce.house.gov/wp-content/uploads/2021/10/NIH-Document-Production-Cover-Letter-2021.10.20_McMorris-Rodgers.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  letter
                </a>{' '}
                sent to House Republicans that NIH{' '}
                <a
                  href="https://reporter.nih.gov/search/yi9zmxlHDUO8yZkhiDs2uA/project-details/8674931#similar-Projects"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  grant-funded
                </a>{' '}
                experiments at the Wuhan Institute of Virology in China had the
                “unexpected result” of creating a coronavirus that was more
                infectious.
              </strong>
            </p>
            <div className="hide-xs show-md">
              <HeroDescription />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-5 col-md-offset-1 col-lg-5 col-lg-offset-1">
          <div className="hero__img">
            <div className="hero__img__inner">
              <div className="main-img">
                <StaticImage
                  src="../images/hero-main.png"
                  alt="Dr. Fauci"
                  layout="fullWidth"
                  placeholder="none"
                  loading="eager"
                />
              </div>
              <div className="nih">
                <NIHLogos />
              </div>
              <motion.div
                className="us"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                  duration: 0.5,
                }}
              >
                <StaticImage
                  src="../images/hero-us.png"
                  alt="USA"
                  layout="fullWidth"
                  placeholder="none"
                />
              </motion.div>
              <motion.div
                className="china"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{
                  delay: 0.1,
                  duration: 0.5,
                }}
              >
                <StaticImage
                  src="../images/hero-china.png"
                  alt="China"
                  layout="fullWidth"
                  placeholder="none"
                />
              </motion.div>
              <Covid i={1} />
              <Covid i={2} />
              <Covid i={3} />
              <Covid i={4} />
              <Covid i={5} />
              <Covid i={6} />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-10 hide-md content-m-t">
          <HeroDescription />
        </div>
      </div>
    </section>
  );
}

import { StaticImage } from 'gatsby-plugin-image';
import Covid from './Covid';

export default function WhyMatterSection() {
  return (
    <div className=" section-m-b content-p-t why-matter">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-10 col-md-12 col-lg-10 col-lg-offset-1">
            <h2 className="h5">Why does this matter?</h2>
            <div className="text-columns">
              <p>
                It matters because of something called{' '}
                <a
                  href="https://www.nature.com/articles/d41586-021-02903-x"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  gain-of-function research
                </a>
                .
              </p>
              <p>
                Formerly banned in the United States, gain-of-function research
                is{' '}
                <a
                  href="https://www.phe.gov/s3/dualuse/Pages/GainOfFunction.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  defined
                </a>{' '}
                as research that improves the ability of a pathogen to cause
                disease and entails manipulating viruses more transmissible.
              </p>
              <p>
                Despite criticisms that gain-of-function research is unethical,
                the U.S. Government{' '}
                <a
                  href="https://www.nih.gov/about-nih/who-we-are/nih-director/statements/nih-lifts-funding-pause-gain-function-research"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ended
                </a>{' '}
                its moratorium on gain-of-function research in 2017.
              </p>
              <blockquote>
                Dr. Fauci made “untruthful assertions” in claiming that the NIH
                did not fund gain-of-function research in Wuhan.
              </blockquote>
              <p>
                The bat coronavirus{' '}
                <a
                  href="https://republicans-energycommerce.house.gov/wp-content/uploads/2021/10/NIH-Document-Production-Cover-Letter-2021.10.20_McMorris-Rodgers.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  research
                </a>{' '}
                conducted under the NIH grant{' '}
                <strong>
                  manipulated a viral contagion in bats and made it
                  transmissible to human-receptors in mice.
                </strong>
              </p>
              <p>
                Dr. Richard Ebright, a molecular biologist at Rutgers
                University,
                <a
                  href="https://news.yahoo.com/biosafety-expert-explains-why-fauci-140753796.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAASzYyzvrdPOC3DH7xpcNvTc8MpHOXTRtUvOFvGyOMMFxGojyZdN1sNyhsJwbahjwyO5DHsT_g5ZyaPuSCVJ2dROuKLXhU-ethiNnQdY2BgRQD5la7yldPPEo3KJh-s-uUVeYbj_Xz7yyR9qiP8M61dRgWr6iTxAkJ5zlrYXbyq5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  commented
                </a>
                , “This research matches, indeed, epitomizes the definition of
                ‘gain-of-function research of concern’[.]”
              </p>
              <p>
                Dr. Ebright{' '}
                <a
                  href="https://www.washingtonexaminer.com/policy/healthcare/fauci-critics-say-nih-letter-debunks-gain-of-function-denial"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  also stated
                </a>{' '}
                that NIAID Director{' '}
                <strong>Dr. Fauci made “untruthful assertions”</strong> in
                claiming that the NIH did not fund gain-of-function research in
                Wuhan.
              </p>
              <p>
                In light of this revelation, Kentucky Senator Rand Paul, Florida
                Governor Ron DeSantis and others have demanded transparency from
                Dr. Fauci on whether this research could be related to the
                outbreak of the COVID-19 pandemic in Wuhan.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="why-matter__img container">
        <figure>
          <figcaption>
            <span className="hide-xs show-md">
              Florida Governor Ron DeSantis and Kentucky Senator Rand Paul.
            </span>
            <span className="hide-md">
              Kentucky Senator Rand Paul and Florida Governor Ron DeSantis.
            </span>
          </figcaption>
          <div className="why-matter__img__inner">
            <Covid i={1} />
            <Covid i={2} />
            <div className="main">
              <StaticImage
                src="../images/desantis-paul.png"
                alt=""
                layout="fullWidth"
              />
            </div>
          </div>
        </figure>
      </div>
    </div>
  );
}

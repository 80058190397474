// @ts-ignore
import { useLocation } from '@reach/router'; // eslint-disable-line import/no-unresolved
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

export interface SEOProps {
  children?: React.ReactNode;
  title: string;
  description?: string;
  canonical?: string;
  image?: string;
  lang?: string;
}

export default function SEO({
  children,
  title,
  description,
  canonical,
  image,
  lang = 'en',
}: SEOProps) {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          keywords
          siteUrl
        }
      }
    }
  `);
  const { pathname } = useLocation() || '';

  const metaDescription = description || siteMetadata.description;
  const metaKeywords = siteMetadata.keywords?.join(', ');
  const url = `${siteMetadata.siteUrl}${pathname}`;
  const canonicalUrl = canonical ? `${siteMetadata.siteUrl}${canonical}` : url;

  const mainFavicon =
    process.env.NODE_ENV === 'development' ? 'favicon-dev' : 'favicon';
  return (
    // @ts-expect-error
    <Helmet
      htmlAttributes={{ lang }}
      titleTemplate={`%s - ${siteMetadata.title}`}
    >
      <html lang="en" />
      {/* Primary Meta Tags */}
      <title>{title || siteMetadata.title}</title>
      <meta name="title" content={title || siteMetadata.title} />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      {/* Canonical */}
      <link rel="canonical" href={canonicalUrl} />
      {/* Favicons */}
      <link rel="icon" type="image/svg+xml" href={`/${mainFavicon}.svg`} />
      <link rel="alternate icon" href={`/${mainFavicon}.png`} sizes="32x32" />
      {/* <link rel="icon" href="/favicon-128.png" sizes="128x128" />
      <link rel="icon" href="/favicon-192.png" sizes="192x192" /> */}
      {/* Android */}
      {/* <link rel="shortcut icon" href="/favicon-196.png" sizes="196x196" /> */}
      {/* iOS */}
      {/* <link rel="apple-touch-icon" href="/favicon-120.png" sizes="120x120" />
      <link rel="apple-touch-icon" href="/favicon-152.png" sizes="152x152" />
      <link rel="apple-touch-icon" href="/favicon-180.png" sizes="180x180" /> */}
      {/* Open Graph */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta
        property="og:image"
        content={image || `${siteMetadata.siteUrl}/social-card.jpg`}
      />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta property="og:site_name" content={siteMetadata.title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      {/* twitter */}
      <meta property="twitter:title" content={title} />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:description" content={metaDescription} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:image"
        content={image || `${siteMetadata.siteUrl}/social-card.jpg`}
      />
      {children}
    </Helmet>
  );
}
